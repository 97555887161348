import { createSvgIcon } from 'theme/utils/createSvgIcon';

import { ReactComponent as BarChart } from './BarChart.svg';
import { ReactComponent as ChevronRight } from './ChevronRight.svg';
import { ReactComponent as Copy } from './Copy.svg';
import { ReactComponent as Delete } from './Delete.svg';
import { ReactComponent as Edit } from './Edit.svg';
import { ReactComponent as Folder } from './Folder.svg';
import { ReactComponent as FramePerson } from './FramePerson.svg';
import { ReactComponent as Login } from './Login.svg';
import { ReactComponent as MoreHorizontal } from './MoreHorizontal.svg';
import { ReactComponent as MoreVertical } from './MoreVertical.svg';
import { ReactComponent as Pin } from './Pin.svg';
import { ReactComponent as Reply } from './Reply.svg';

export const BarChartIcon = createSvgIcon(BarChart, 'BarChart');
export const ChevronRightIcon = createSvgIcon(ChevronRight, 'ChevronRight');
export const CopyIcon = createSvgIcon(Copy, 'Copy');
export const DeleteIcon = createSvgIcon(Delete, 'Delete');
export const EditIcon = createSvgIcon(Edit, 'Edit');
export const FolderIcon = createSvgIcon(Folder, 'Folder');
export const FramePersonIcon = createSvgIcon(FramePerson, 'FramePerson');
export const LoginIcon = createSvgIcon(Login, 'Login');
export const MoreHorizontalIcon = createSvgIcon(MoreHorizontal, 'MoreHorizontal');
export const MoreVerticalIcon = createSvgIcon(MoreVertical, 'MoreVertical');
export const PinIcon = createSvgIcon(Pin, 'Pin');
export const ReplyIcon = createSvgIcon(Reply, 'Reply');
