import { ComponentType } from 'react';

export const chartNonDataKeys = ['id', 'name'];

export type BasicChartDataPoint = {
  id?: number | string;
  name: string | number;
  value?: number;
  /** @deprecated in charts v2 */
  percentage?: number;
  [key: string]: number | undefined | string;
};

export type BasicChartData = BasicChartDataPoint[];

export type ChartMouseEventAxisData = {
  coordinate: number;
  index: number;
  offset: number;
  value: string;
};

export type ChartMouseEventChartData = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type TooltipContentProps = {
  name: string;
  value: number;
  dataKey: string;
  range?: [number, number];
  isPercentageValue?: boolean;
};

export type TooltipContent = ComponentType<TooltipContentProps>;

export type ChartBreakdownData = {
  data: Record<string, BasicChartData>;
  dataKeys: string[];
};

export type BasicChartProps = {
  data: BasicChartData;
  breakdownData?: ChartBreakdownData;
  dataKeys?: string[];
  labelValues?: string;
  labelNames?: string;
  height?: number | string;
  TooltipContent?: TooltipContent;
  isPercentageValue?: boolean;
};
