import React, { FC, MutableRefObject, ReactNode } from 'react';
import { isMobile } from 'react-device-detect';

import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import { Stack, Typography } from '@mui/material';
import { useHover } from 'common/hooks';

import {
  DragIndicator,
  ExpandableBoxContent,
  ExpandableBoxSummary,
  ExpandableBoxWrapper,
} from './ExpandableBox.styled';

type Props = {
  isExpanded: boolean;
  onChange(value: boolean): void;
  summary: ReactNode;
  handleRef: MutableRefObject<HTMLDivElement | null>;
  showDragIndicator?: boolean;
};

export const ExpandableBox: FC<Props> = ({ isExpanded, onChange, summary, handleRef, showDragIndicator, children }) => {
  const [ref, isHovered] = useHover();
  const isDragIndicatorVisible = showDragIndicator && (isHovered || isMobile);

  return (
    <Stack component="section" gap={2} ref={ref}>
      <ExpandableBoxWrapper
        disableGutters
        // TODO Requires update of @mui/material@^5.15.18 but doesn't work as expected
        // Removing `ExpandableBoxContent` from the DOM is handled by `isExpanded` state
        // slotProps={{ transition: { unmountOnExit: true } }}
        expanded={isExpanded}
        onChange={() => onChange(!isExpanded)}
      >
        <ExpandableBoxSummary expandIcon={<ChevronRightOutlined />}>
          <Stack flex={1} minHeight={24}>
            {typeof summary === 'string' ? (
              <Typography variant="natter-text-sm" fontWeight={600} component="h2">
                {summary}
              </Typography>
            ) : (
              summary
            )}
          </Stack>

          <DragIndicator
            ref={handleRef}
            // below is a workaround for known Firefox issue: https://bugzilla.mozilla.org/show_bug.cgi?id=800050
            // https://natterco.atlassian.net/browse/N2-2106
            sx={{ display: isDragIndicatorVisible ? undefined : 'none' }}
          />
        </ExpandableBoxSummary>

        {isExpanded && <ExpandableBoxContent>{children}</ExpandableBoxContent>}
      </ExpandableBoxWrapper>
    </Stack>
  );
};
