import React, { FC, useMemo } from 'react';

import {
  Bar,
  CartesianGrid,
  Legend,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT } from '../../constants';
import { useChartAxis, useChartColors, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateTicks } from '../../utils';
import { calculateHistogramData } from './utils';

export const Histogram: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
}) => {
  const classes = useChartStyles();
  const { getColor } = useChartColors();
  const { tickStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const histogramData = useMemo(() => calculateHistogramData({ data, dataKeys }), [data, dataKeys]);
  const yTicks = useMemo(() => calculateTicks({ data: histogramData, dataKeys }), [histogramData, dataKeys]);
  const { activeKey, handleMouseEnter } = useTooltip();

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <RechartsBarChart
        barCategoryGap={1}
        barGap={2}
        data={histogramData}
        margin={{ right: 50, left: 50, bottom: 40 }}
        layout="horizontal"
        className={classes.verticalChart}
      >
        <CartesianGrid vertical={false} />
        <XAxis hide dataKey="name" />
        <XAxis
          dataKey="name"
          xAxisId="values"
          tickLine={false}
          axisLine={false}
          tickMargin={16}
          interval={0}
          scale="band"
          tick={tickStyles}
          label={renderAxisXLabel(labelNames)}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLineStyles}
          padding={{ top: 10 }}
          interval={0}
          ticks={yTicks}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          width={40}
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && <Legend verticalAlign="top" align="right" />}
        {dataKeys.map((key, dataKeyIndex) => (
          <Bar key={key} dataKey={key} fill={getColor(dataKeyIndex)} onMouseEnter={handleMouseEnter(key)} />
        ))}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};
