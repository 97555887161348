import React, { FC, useMemo, useState } from 'react';

import { ErrorBoundary } from 'common/components';
import { BasicChartProps } from 'common/components/Charts';

import { chartsMap } from '../../ChartCard';
import {
  ChartCardChartWrapper,
  ChartCardHeader,
  ChartCardHeaderRightSection,
  ChartCardTitle,
  ChartCardWrapper,
} from '../../ChartCard.styled';
import { ChartErrorFallback } from '../ChartErrorFallback';
import { ChartType } from '../ChartSelect';
import { AVAILABLE_CHART_TYPES, SingleChoiceQuestionChartCardActions } from './components';

export type ChartCardProps = {
  title: string;
  chartProps: BasicChartProps;
  chartId: string;
  hasError?: boolean;
};

export const SingleChoiceQuestionChartCard: FC<ChartCardProps> = ({
  title,
  chartProps,
  chartId,
  hasError = false,
  children,
}) => {
  const [selectedChartType, setSelectedChartType] = useState(AVAILABLE_CHART_TYPES[0]);
  const SelectedChartComponent = chartsMap[selectedChartType];
  const [breakDownBy, setBreakDownBy] = useState<string>();

  const handleBreakDownByChange = (value: string) => {
    setBreakDownBy(value);
    setSelectedChartType(value ? ChartType.HorizontalStackedBar : AVAILABLE_CHART_TYPES[0]);
  };

  const chartDataOverrides = useMemo<Pick<BasicChartProps, 'data' | 'dataKeys' | 'labelValues'>>(
    () =>
      breakDownBy
        ? {
            data: chartProps.breakdownData?.data?.[breakDownBy] ?? [],
            dataKeys: chartProps.breakdownData?.dataKeys ?? [],
            labelValues: 'Percentage (%) of Responses',
          }
        : {
            data: chartProps.data,
            dataKeys: chartProps.dataKeys,
          },
    [breakDownBy, chartProps]
  );

  const breakdownOptions = useMemo(
    () => chartProps.breakdownData?.data && Object.keys(chartProps.breakdownData.data),
    [chartProps]
  );

  return (
    <ChartCardWrapper>
      <ChartCardHeader>
        <ChartCardTitle title={title}>{title}</ChartCardTitle>
        <ChartCardHeaderRightSection>
          <SingleChoiceQuestionChartCardActions
            chartId={chartId}
            title={title}
            data={chartDataOverrides.data}
            selectedChartType={selectedChartType}
            breakDownBy={breakDownBy}
            breakdownOptions={breakdownOptions}
            onBreakDownByChange={handleBreakDownByChange}
            onSelectedChartTypeChange={setSelectedChartType}
          />
        </ChartCardHeaderRightSection>
      </ChartCardHeader>
      <ErrorBoundary fallback={<ChartErrorFallback />} hasError={hasError}>
        <ChartCardChartWrapper>
          <SelectedChartComponent {...chartProps} {...chartDataOverrides} isPercentageValue={!!breakDownBy}>
            {children}
          </SelectedChartComponent>
        </ChartCardChartWrapper>
      </ErrorBoundary>
    </ChartCardWrapper>
  );
};
