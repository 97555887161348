import React, { FC, Fragment } from 'react';

import { Divider, Menu as MuiMenu, MenuProps as MuiMenuProps, PopoverOrigin, SxProps, Theme } from '@mui/material';

import { MenuItem, type MenuItemProps } from './components';
import { MenuItemType } from './types';

export type MenuProps = Pick<MenuItemProps, 'onClose'> &
  Pick<MuiMenuProps, 'sx' | 'transformOrigin' | 'anchorOrigin' | 'slotProps'> & {
    anchor: MuiMenuProps['anchorEl'];
    items: MenuItemType[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    sx?: SxProps<Theme>;
    activeIndex?: number;
  };

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const defaultTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const Menu: FC<MenuProps> = ({
  anchor,
  onClose,
  items,
  anchorOrigin = defaultAnchorOrigin,
  transformOrigin = defaultTransformOrigin,
  activeIndex,
  ...props
}) => {
  const isOpen = Boolean(anchor);

  return (
    <MuiMenu
      open={isOpen}
      anchorEl={anchor}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClick={(e) => e.stopPropagation()}
      disableRestoreFocus={true}
      onClose={onClose}
      {...props}
    >
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.withDivider && <Divider variant="middle" component="li" sx={{ mx: 1.5 }} />}
          <MenuItem isActive={activeIndex === index} item={item} onClose={onClose} parentMenuOpen={isOpen} />
        </Fragment>
      ))}
    </MuiMenu>
  );
};
