import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { InputWrapper, InputWrapperProps, TextInput } from 'common/components/_legacy/Form';
import { dataToCsvFile } from 'common/utils';
import { guestValidationSchema, guestWithRoleValidationSchema } from 'community/pages/CommunitySettings/validators';
import { UserRoleName } from 'domain/UserProfile';
import _uniqBy from 'lodash/uniqBy';
import Papa from 'papaparse';
import { useAppDispatch } from 'store';
import { addAppErrorMessage } from 'store/features/alerts';

import { useGuestsInputStyles } from './GuestsInput.styles';
import { GuestsList } from './components/GuestsList';
import { UserWithRole, getUsersFromData, mapUsersWithRoles } from './utils';

const sampleFile = [
  `email,name,role,privileges`,
  `sample@mail.com,Optional Guest Name,${UserRoleName.CommunityMember}`,
].join('\n');

interface GuestsInputProps extends InputWrapperProps {
  hideEmailInput?: boolean;
  disableRoleEdit?: boolean;
  onKeyDown?: React.KeyboardEventHandler<unknown> | undefined;
  placeholder?: string;
}

export const GuestsInput: React.FC<GuestsInputProps> = ({
  name,
  hint,
  label,
  onKeyDown,
  hideEmailInput = false,
  disableRoleEdit = false,
  placeholder = 'Enter email address',
  ...props
}) => {
  const dispatch = useAppDispatch();
  const methods = useFormContext();
  const guests = useWatch({ name });
  const classes = useGuestsInputStyles();
  const expectedHeaders = useMemo(
    () => (disableRoleEdit ? ['email'] : ['email', 'role', 'privileges']),
    [disableRoleEdit]
  );
  const validation = disableRoleEdit ? guestValidationSchema : guestWithRoleValidationSchema;

  useEffect(() => {
    methods.register(name);
  }, [methods, name]);

  const validateUsers = useCallback(
    (users: UserWithRole[]) =>
      users.filter((user) => {
        try {
          validation.validateSync(user);
          return true;
        } catch {
          return false;
        }
      }),
    [validation]
  );

  const handleCompleteUpload = useCallback(
    ({ data }: { data: string[][] }) => {
      const newGuests = getUsersFromData({ data, expectedHeaders });
      const uniqueUsers = _uniqBy(newGuests.concat(guests), 'email');
      const validUniqueUsers = validateUsers(mapUsersWithRoles(uniqueUsers));
      const invalidUsersCount = uniqueUsers.length - validUniqueUsers.length;
      if (invalidUsersCount) {
        dispatch(addAppErrorMessage(`Found ${invalidUsersCount} user(s) with malformed data while parsing CSV file.`));
      }

      methods.setValue(name, validUniqueUsers);
    },
    [dispatch, expectedHeaders, guests, methods, name, validateUsers]
  );

  const handleFileUpload = useCallback(
    (e) => {
      const file = e?.target?.files?.[0];
      if (!file) {
        return;
      }
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: handleCompleteUpload,
      });
    },
    [handleCompleteUpload]
  );

  return (
    <InputWrapper label={label} hint={hint} name={name} {...props}>
      <Stack gap={3}>
        {!hideEmailInput && (
          <Box>
            <TextInput name="email" label="Email address" placeholder={placeholder} onKeyDown={onKeyDown} />
          </Box>
        )}
        <Box>
          <Stack
            className={classes.inputContainer}
            data-testid="guestInput"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <FontAwesomeIcon icon={faFileUpload} size="2x" color="primary" />
            <Typography marginTop={2} color="black">
              Select or drag and drop file here
            </Typography>
            <input
              className={classes.input}
              type="file"
              name={name}
              accept=".csv"
              onChange={handleFileUpload}
              data-testid="GuestsInput-file"
              onClick={(event) => {
                // to allow selecting the same file multiple times
                // @ts-expect-error Property 'value' does not exist on type 'EventTarget'
                event.target.value = null;
              }}
            />
            <Button
              size="small"
              variant="contained-light"
              sx={{
                marginTop: 2,
                pointerEvents: 'none',
              }}
            >
              Select file
            </Button>
          </Stack>
          <Typography
            onClick={() => {
              dataToCsvFile(sampleFile, 'Sample File.csv');
            }}
            className={classes.sampleFile}
            marginTop={1}
          >
            <span className={classes.underline}>Download a sample</span> CSV file template to see an example of the
            format required
          </Typography>
        </Box>
        {!!guests?.length && <GuestsList name={name} />}
      </Stack>
    </InputWrapper>
  );
};
