import React, { ComponentProps, FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { DescriptionWithExplanation } from 'common/components';

type SectionHeaderProps = {
  title: string;
  description?: string;
  variant?: ComponentProps<typeof Typography>['variant'];
  explanation?: string;
};

export const SectionHeader: FC<SectionHeaderProps> = ({
  variant = 'natter-text-sm',
  title,
  description,
  explanation,
}) => (
  <Stack component="header" gap={1}>
    <Typography variant={variant} fontWeight={600} color="text.primary" component="h2" data-testid="section-title">
      {title}
    </Typography>
    {description && <DescriptionWithExplanation description={description} explanation={explanation} />}
  </Stack>
);
