import React, { FC, useMemo } from 'react';

import {
  Bar,
  CartesianGrid,
  LabelList,
  Legend,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { BarChartLabel, Tick, TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT } from '../../constants';
import { useChartAxis, useChartColors, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateTicks } from '../../utils';

export const BarChart: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
}) => {
  const classes = useChartStyles();
  const { getColor } = useChartColors();
  const { tickStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const ticks = useMemo(() => calculateTicks({ data, dataKeys }), [data, dataKeys]);
  const { activeKey, handleMouseEnter } = useTooltip();

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <RechartsBarChart
        barCategoryGap={1}
        barGap={2}
        data={data}
        margin={{ right: 50, left: 50, bottom: 40 }}
        layout="horizontal"
        className={classes.verticalChart}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          tick={(props) => <Tick {...props} axis="X" />}
          tickMargin={16}
          interval={0}
          label={renderAxisXLabel(labelNames)}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLineStyles}
          padding={{ top: 10 }}
          interval={0}
          ticks={ticks}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          width={40}
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && <Legend verticalAlign="top" align="right" />}
        {dataKeys.map((key, index) => {
          const color = getColor(index);
          return (
            <Bar key={key} dataKey={key} fill={color} onMouseEnter={handleMouseEnter(key)}>
              <LabelList dataKey={key} fill={color} content={BarChartLabel} />
            </Bar>
          );
        })}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};
