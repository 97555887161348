import React from 'react';

import { BasicChartDataPoint, type ChartBreakdownData } from 'common/components/Charts';
import { uuid } from 'common/utils';
import { sum, sumBy } from 'lodash';
import { ChartCardProps, ChartType } from 'pages/InsightsReport/components/ChartCard';
import { ChartTooltipContent } from 'pages/InsightsReport/components/ChartTooltipContent';
import {
  AnswerStatistics,
  AnswerStatisticsType,
  CommunityTagStatistics,
  MultipleChoiceSingleSelectAnswerStatistics,
  NPSAnswerStatistics,
  RankingAnswerStatistics,
  SliderAnswerStatistics,
  TextAnswerStatistics,
  isMultipleChoiceMultipleSelectAnswerStatistics,
  isMultipleChoiceSingleSelectAnswerStatistics,
  isNPSAnswerStatistics,
  isRankingAnswerStatistics,
  isSliderAnswerStatistics,
  isTextAnswerStatistics,
} from 'store/apis/insightsReport';

import { RankingTooltip } from '../components';

export const mapAnswerStatisticsToChartCardProps = (answerStatistics: AnswerStatistics): ChartCardProps => {
  if (isTextAnswerStatistics(answerStatistics)) {
    return mapAnswerStatisticsToTextProps(answerStatistics);
  }
  if (
    isMultipleChoiceSingleSelectAnswerStatistics(answerStatistics) ||
    isMultipleChoiceMultipleSelectAnswerStatistics(answerStatistics)
  ) {
    return mapAnswerStatisticsToMultipleChoiceProps(answerStatistics);
  }
  if (isNPSAnswerStatistics(answerStatistics)) {
    return mapAnswerStatisticsToNPSProps(answerStatistics);
  }
  if (isRankingAnswerStatistics(answerStatistics)) {
    return mapAnswerStatisticsToRankingProps(answerStatistics);
  }
  if (isSliderAnswerStatistics(answerStatistics)) {
    return mapAnswerStatisticsToSliderProps(answerStatistics);
  }
  return handleUnknownAnswerStatistics(answerStatistics);
};

const mapAnswerStatisticsToTextProps = (answerStatistics: TextAnswerStatistics) => {
  const chartCardProps = initChartCardProps(answerStatistics.type);
  const visibleThemes = answerStatistics.themes.filter((theme) => !theme.isHidden);
  chartCardProps.title = `${answerStatisticsTypeToTitleMap[AnswerStatisticsType.TextAnswerStatistics]} (${visibleThemes.length})`;
  chartCardProps.chartProps.labelNames = 'Theme name';
  chartCardProps.chartProps.labelValues = 'Number of mentions';
  chartCardProps.chartProps.data = visibleThemes.map(({ theme, count }) => ({
    name: theme,
    value: count,
  }));
  chartCardProps.availableChartTypes = [ChartType.HorizontalBar];

  const totalCount = sumBy(chartCardProps.chartProps.data, 'value');
  chartCardProps.chartProps.TooltipContent = ({ name, value }) => (
    <ChartTooltipContent name={name} value={value} totalCount={totalCount} />
  );

  return chartCardProps;
};

const mapAnswerStatisticsToMultipleChoiceProps = (answerStatistics: MultipleChoiceSingleSelectAnswerStatistics) => {
  const chartCardProps = initChartCardProps(answerStatistics.type);

  chartCardProps.chartProps.data = Object.entries(answerStatistics.answersPerChoice).map(([key, value]) => ({
    name: key,
    value,
  }));
  chartCardProps.chartProps.breakdownData = groupTagStatisticsByTagCategory(answerStatistics.communityTagStatistics);
  chartCardProps.availableChartTypes = [ChartType.Donut, ChartType.Pie, ChartType.HorizontalBar, ChartType.Bar];

  const totalCount = sumBy(chartCardProps.chartProps.data, 'value');
  chartCardProps.chartProps.TooltipContent = ({ name, value, dataKey, isPercentageValue }) => (
    <ChartTooltipContent
      name={name}
      value={value}
      dataKey={dataKey}
      isPercentageValue={isPercentageValue}
      totalCount={totalCount}
    />
  );

  return chartCardProps;
};

const groupTagStatisticsByTagCategory = (data: CommunityTagStatistics): ChartBreakdownData => ({
  data: Object.entries(data).reduce(
    (breakdownData, [answerTitle, statistic]) => {
      statistic.forEach(({ communityTagCategoryName, communityTagName, count }) => {
        if (!breakdownData[communityTagCategoryName]) {
          breakdownData[communityTagCategoryName] = [];
        }
        // eslint-disable-next-line functional/no-let
        let dataPoint = breakdownData[communityTagCategoryName].find(({ name }) => name === communityTagName);
        if (!dataPoint) {
          dataPoint = {
            name: communityTagName,
          };
          breakdownData[communityTagCategoryName].push(dataPoint);
        }
        dataPoint[answerTitle] = (Number(dataPoint[answerTitle]) || 0) + count;
      });
      return breakdownData;
    },
    {} as ChartBreakdownData['data']
  ),
  dataKeys: Object.keys(data),
});

const mapAnswerStatisticsToNPSProps = (answerStatistics: NPSAnswerStatistics) => {
  const chartCardProps = initChartCardProps(answerStatistics.type);
  chartCardProps.chartProps.data = Object.entries(answerStatistics.answersPerValue).map(([key, value]) => ({
    name: key,
    value,
  }));
  chartCardProps.availableChartTypes = [ChartType.Donut, ChartType.Pie, ChartType.HorizontalBar, ChartType.Bar];

  const totalCount = sumBy(chartCardProps.chartProps.data, 'value');
  chartCardProps.chartProps.TooltipContent = ({ name, value }) => (
    <ChartTooltipContent name={name} value={value} totalCount={totalCount} />
  );

  return chartCardProps;
};

const mapAnswerStatisticsToRankingProps = (answerStatistics: RankingAnswerStatistics) => {
  const chartCardProps = initChartCardProps(answerStatistics.type);
  const dataKeys: string[] = Object.keys(answerStatistics.answersPerChoice).map((_, index) => (index + 1).toString());
  chartCardProps.chartProps.dataKeys = dataKeys;
  chartCardProps.availableChartTypes = [ChartType.VerticalStackedBar, ChartType.HorizontalStackedBar];

  chartCardProps.chartProps.data = Object.entries(answerStatistics.answersPerChoice).map(
    ([key, value]: [string, Record<number, number>]) =>
      dataKeys.reduce(
        (prev, curr) => {
          prev[curr] = value[Number(curr) - 1] ?? 0;
          return prev;
        },
        { name: key } as BasicChartDataPoint
      )
  );

  const firstEntry: Record<number, number> = Object.values(answerStatistics.answersPerChoice)[0];
  const totalCount = firstEntry ? sum(Object.values(firstEntry)) : 0;
  chartCardProps.chartProps.TooltipContent = ({ name, value, dataKey }) => (
    <RankingTooltip name={name} value={value} dataKey={dataKey} totalCount={totalCount} />
  );

  return chartCardProps;
};

const mapAnswerStatisticsToSliderProps = (answerStatistics: SliderAnswerStatistics) => {
  const chartCardProps = initChartCardProps(answerStatistics.type);
  chartCardProps.chartProps.data = Object.entries(answerStatistics.answersPerValue).map(([key, value]) => ({
    name: key,
    value,
  }));
  chartCardProps.availableChartTypes = [ChartType.Histogram, ChartType.DensityPlot];

  const totalCount = sumBy(chartCardProps.chartProps.data, 'value');
  chartCardProps.chartProps.TooltipContent = ({ value, range }) => (
    <ChartTooltipContent name={`${range?.[0]} to ${range?.[1]}`} value={value} totalCount={totalCount} />
  );

  return chartCardProps;
};

const handleUnknownAnswerStatistics = (answerStatistics: AnswerStatistics): ChartCardProps => {
  const chartCardProps = initChartCardProps(answerStatistics.type);
  console.error('Unrecognized type of question: ', answerStatistics.type);
  chartCardProps.hasError = true;
  return chartCardProps;
};

const initChartCardProps = (type: AnswerStatisticsType): ChartCardProps => ({
  availableChartTypes: [],
  chartProps: {
    data: [],
    labelNames: 'Response',
    labelValues: 'Number of responses',
  },
  title: answerStatisticsTypeToTitleMap[type],
  chartId: uuid(),
});

const answerStatisticsTypeToTitleMap: Record<AnswerStatisticsType, string> = {
  [AnswerStatisticsType.TextAnswerStatistics]: 'Dominant Themes',
  [AnswerStatisticsType.MultipleChoiceSingleSelectAnswerStatistics]: 'Single Choice Question',
  [AnswerStatisticsType.MultipleChoiceMultipleSelectAnswerStatistics]: 'Multiple Choice Question',
  [AnswerStatisticsType.RankingAnswerStatistics]: 'Ranking Question',
  [AnswerStatisticsType.SliderAnswerStatistics]: 'Slider Question',
  [AnswerStatisticsType.NPSAnswerStatistics]: 'NPS Question',
};
