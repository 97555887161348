import React, { FC } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';

import { Wrapper } from './FormFieldHint.styled';

type Props = {
  error?: boolean;
  justifyContent?: 'center' | 'start';
  noWrap?: boolean;
};

export const FormFieldHint: FC<Props> = ({ children, error, justifyContent = 'start', noWrap }) =>
  children ? (
    <Wrapper justifyContent={justifyContent} data-testid="FormFieldHint">
      {error ? (
        <ErrorIcon titleAccess="Error" sx={{ width: 14 }} color="error" />
      ) : (
        <InfoOutlinedIcon titleAccess="Info" sx={{ width: 14 }} />
      )}
      <Typography component="div" variant="natter-text-xs" color={error ? 'error' : undefined} noWrap={noWrap}>
        {children}
      </Typography>
    </Wrapper>
  ) : null;
