import React, { FC, ReactNode } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from '@mui/material';
import { SectionHeader } from 'common/layout';

import {
  NavigationCardHeader,
  NavigationCardList,
  NavigationCardListItem,
  NavigationCardWrapper,
} from './NavigationCard.styled';

type Props = {
  title: string;
  items: Array<{ title: string; href?: string; description?: ReactNode }>;
};

export const NavigationCard: FC<Props> = ({ title, items }) => (
  <NavigationCardWrapper component="section">
    <NavigationCardHeader>
      <SectionHeader variant="natter-text-xl" title={title} />
    </NavigationCardHeader>

    <NavigationCardList>
      {items.map((item) => (
        <NavigationCardListItem
          key={item.title}
          href={item.href}
          component={item.href?.startsWith('http') ? 'a' : undefined}
          as={!item.href ? Typography : undefined}
        >
          <Typography component="span" sx={{ flex: 1 }}>
            {item.title}
          </Typography>

          {item.description ?? <ArrowForwardIcon />}
        </NavigationCardListItem>
      ))}
    </NavigationCardList>
  </NavigationCardWrapper>
);
