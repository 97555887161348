import React, { FC, ReactNode } from 'react';

import { Box, Stack } from '@mui/material';
import { enumToOptionItems } from 'common/components/Inputs';
import { TimeUnit } from 'domain/Common';

import { Select } from '../Select';
import { TextFieldWithLabel } from '../TextFieldWithLabel';

const timePeriodOptions = enumToOptionItems(TimeUnit);

export const RepeatDate: FC<{
  amountName: string;
  unitName: string;
  label: ReactNode;
  disabled?: boolean;
  unitDisabled?: boolean;
}> = ({ amountName, unitName, label, disabled, unitDisabled }) => (
  <Stack direction={{ xs: 'column', md: 'row' }} gap={1}>
    <Box width={{ xs: 1, md: 200 }} flexShrink={0}>
      <TextFieldWithLabel
        disabled={disabled}
        name={amountName}
        label={label}
        type="number"
        InputProps={{ inputProps: { min: 1, max: 365, 'data-testid': 'RepeatDate-input-amount' } }}
        fullWidth
      />
    </Box>

    <Select
      name={unitName}
      options={timePeriodOptions}
      disabled={disabled || unitDisabled}
      data-testid="RepeatDate-input-unit"
    />
  </Stack>
);
