export interface ApiRequestParams<SortByType = string> {
  pageSize: number;
  page: number;
  sortBy: SortByType;
  sortOrder: SortOrder;
  searchPhrase: string;
}

export interface PaginatedApiPayload<T> {
  /**
   * @deprecated since version 1.42.0, please use **data** instead
   */
  content: T[];
  /**
   * @deprecated since version 1.42.0
   */
  pageSize: number;
  data: T[];
  totalCount: number;
}

export interface PaginatedApiPayloadV2<T> {
  data: T[];
  totalCount: number;
}

export type SortOrder = 'asc' | 'desc';

export type BasicPaginationParams<T = Record<string, unknown>> = {
  page: number;
  pageSize: number;
  searchPhrase?: string;
  filters?: T;
  sortBy?: string;
  sortOrder?: SortOrder;
};

export interface PaginationParams extends BasicPaginationParams {
  sortBy: string;
  sortOrder: SortOrder;
}

export interface Tag {
  id: number;
  name: string;
  isSelected?: boolean;
}

export const KnownTagsCategoryTypes = {
  Additional: 'ADDITIONAL',
};

export interface TagsCategory {
  id: number | null;
  name: string;
  type: string;
  isSingleSelection: boolean;
  tags: Tag[];
}

export interface TagsCategoryV2 {
  id: number | null;
  name: string;
  type: string;
  singleSelection: boolean;
  tags: Omit<Tag, 'isSelected'>[];
}

type DataAttributes = `data-${string}`;
export interface DataAttributesProps<T = string> {
  [dataAttributes: DataAttributes]: T;
}

export type WithDataAttributes<T extends Record<string, unknown>> = T & Record<`data-${string}`, unknown>;

export type Timestamp = number;

/** ISO Date string e.g. "2024-04-16T08:42:50.181Z"
 * @deprecated API is sending now Timestamp as global JSON serializer
 */
export type DateISOString = ReturnType<Date['toISOString']>;

export type Nullable<T = unknown> = T | null;

export type PaginatedApiPayloadV3<SortField extends string = string> = {
  page: number;
  size: number;
  sortDir: 'asc' | 'desc';
  sortField: SortField;
};

export type PaginatedApiResponse<T> = {
  data: T[];
  totalCount: number;
};

export enum TimeUnit {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS',
  Years = 'YEARS',
}

export enum Weekday {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}
