import { maxBy } from 'lodash';

import { BasicChartData, BasicChartDataPoint, chartNonDataKeys } from '../types';

export const calculateTicks = ({
  data,
  dataKeys = ['value'],
  numberOfTicks,
  isPercentageValue,
}: {
  data: Record<string, number | string | undefined>[];
  dataKeys?: string[];
  numberOfTicks?: number;
  isPercentageValue?: boolean;
}) => {
  const maxValue = calculateMaxValue(data, dataKeys);
  const interval = calculateInterval(maxValue);
  // +1 because we need to display 0, another +1 to display one line above max value
  numberOfTicks = numberOfTicks ?? maxValue / interval + (isPercentageValue ? 1 : 2);
  return Array.from({ length: numberOfTicks }).map((_, i) => i * interval);
};

const calculateMaxValue = (data: Record<string, number | string | undefined>[], dataKeys: string[] = ['value']) => {
  const maxValue = Number(maxBy(dataKeys.map((dataKey) => maxBy(data, dataKey)?.[dataKey])));
  return isNaN(maxValue) ? 0 : maxValue;
};

const calculateInterval = (value: number) => {
  // https://stackoverflow.com/questions/23917074/javascript-flooring-number-to-order-of-magnitude
  const order = Math.floor(Math.log(Math.max(value - 1, 1)) / Math.LN10 + 0.000000001);
  return 10 ** order;
};

export const initFrequencyDistributionData = ({
  dataKeys,
  xAxisDomain,
  calculateTickName,
}: {
  xAxisDomain: [number, number];
  dataKeys: string[];
  calculateTickName(tick: number, nextTick: number): number;
}) => {
  const min = xAxisDomain[0];
  const max = xAxisDomain[1];
  const distance = max - min;
  const numberOfBars = distance >= 10 ? 10 : distance;
  const interval = distance / numberOfBars;
  const numberOfTicks = numberOfBars + 1;
  const histogramData = Array.from({ length: numberOfTicks }).map((_, index) => {
    const tick = min + Math.round(index * interval);
    const x1 = min + Math.round((index + 1) * interval);
    const histogramDataPoint: BasicChartDataPoint = {
      name: calculateTickName(tick, x1),
      x0: index === 0 || interval === 1 ? tick : tick + 1,
      x1,
    };
    dataKeys.forEach((dataKey) => {
      histogramDataPoint[dataKey] = 0;
    });
    return histogramDataPoint;
  });

  return {
    histogramData,
    interval,
  };
};

export const distributeFrequency = ({
  initialData,
  histogramData,
  dataKeys,
}: {
  initialData: BasicChartData;
  histogramData: BasicChartData;
  dataKeys: string[];
}) => {
  initialData.forEach((dataPoint) => {
    dataKeys.forEach((dataKey) => {
      const value = Number(dataPoint.name);
      const count = Number(dataPoint[dataKey]);
      if (isNaN(value) || isNaN(count)) return;

      const bucket = histogramData.findIndex(
        (tick) => typeof tick.x0 === 'number' && typeof tick.x1 === 'number' && tick.x0 <= value && tick.x1 >= value
      );
      if (typeof histogramData[bucket][dataKey] === 'number') {
        histogramData[bucket][dataKey] += count;
      }
    });
  });
};

export const mapToStackedChartValues = ({
  data,
  dataKeys = ['value'],
}: {
  data: BasicChartData;
  dataKeys?: string[];
}): { value: number }[] =>
  data.map((dataPoint) => ({
    value: dataKeys.reduce((sum, currentKey) => {
      const currentValue = dataPoint[currentKey];
      if (typeof currentValue !== 'number') return sum;
      return sum + currentValue;
    }, 0),
  }));

export const mapToStackedChartPercentages = (data: BasicChartData): BasicChartData =>
  data.map((dataPoint) => {
    const entries = Object.entries(dataPoint);
    const totalValue = entries
      .filter(([key]) => !isChartNonDataKey(key))
      .reduce((total, [, value]) => total + (Number(value) || 0), 0);

    return entries.reduce((newDataPoint, [key, value]) => {
      if (key === 'name') {
        newDataPoint[key] = value!;
      } else {
        const percentageValue = ((Number(value) || 0) * 100) / totalValue;
        newDataPoint[key] = isNaN(percentageValue) ? 0 : percentageValue;
      }
      return newDataPoint;
    }, {} as BasicChartDataPoint);
  });

export const isChartNonDataKey = (key: string) => chartNonDataKeys.includes(key);
