import React, { forwardRef, useCallback, useState } from 'react';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { type TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ClearButton } from 'common/components/Buttons/ClearButton';

import { TextField } from '../TextField';

export const DEFAULT_DATE_FORMAT = 'EEEE MMMM do, yyyy';

export type DatePickerProps = {
  disabled?: boolean;
  error?: boolean;
  format?: string;
  fullWidth?: boolean;
  hint?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  value: Date | null;
  minDate?: Date | undefined;
  variant?: 'filled' | 'outlined' | 'standard';
  onAccept?(date: Date | null): void;
  onBlur?(): void;
  onChange(value: Date | null): void;
  onClose?(): void;
  onOpen?(): void;
};

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      error,
      format = DEFAULT_DATE_FORMAT,
      fullWidth = true,
      hint,
      label,
      placeholder = 'Select a date',
      required,
      value,
      minDate,
      variant,
      onAccept,
      onBlur,
      onChange,
      onClose,
      onOpen,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleAccept = useCallback(
      (date: Date | null) => {
        onChange(date);
        onAccept?.(date);
      },
      [onAccept, onChange]
    );

    const handleOpen = useCallback(() => {
      setIsOpen(true);
      onOpen?.();
    }, [onOpen]);

    const handleClose = useCallback(() => {
      setIsOpen(false);
      onClose?.();
    }, [onClose]);

    const handleClearInput = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onChange(null);
      },
      [onChange]
    );

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiDatePicker
          disablePast
          minDate={minDate}
          format={format}
          inputRef={ref}
          label={label}
          open={isOpen}
          slots={{
            openPickerIcon: CalendarTodayOutlinedIcon,
            textField: TextField,
          }}
          slotProps={{
            openPickerButton: {
              disableRipple: true,
              sx: ({ palette }) => ({
                color: palette.grey[600],

                ':hover': {
                  backgroundColor: 'transparent',
                },
              }),
            },
            textField: {
              error,
              fullWidth,
              hint,
              placeholder,
              required,
              suffix: value ? <ClearButton onClick={handleClearInput} /> : undefined,
              onBlur,
              onClick: handleOpen,
              variant,
            } as unknown as TextFieldProps,
            field: {
              readOnly: true,
              sx: {
                '& .MuiInputBase-root, input': {
                  cursor: 'pointer',
                },
                '& .MuiInputBase-root::before, .MuiInputBase-root.MuiInput-root:hover:before, .MuiInputBase-root::after':
                  {
                    borderBottom: 'none',
                  },
              },
            },
          }}
          value={value}
          onAccept={handleAccept}
          onChange={onChange}
          onClose={handleClose}
          onOpen={handleOpen}
          {...props}
        />
      </LocalizationProvider>
    );
  }
);
